import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ //const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class InterviewService {
    
    async create(data) {
        try {
            const res = await axios.post(API_URL + 'interview', data,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }

    async getAll(sd, ed, rec, tl, client, loc, source, empid, role ) {
        try {
            const res = await axios.get(API_URL + 'interview?sd=' + sd + '&ed=' + ed + '&rec=' + rec + '&tl=' + tl + '&client=' + client + '&loc=' + loc + '&source=' + source  + '&empid=' + empid  + '&role=' + role ,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    //Not in Use
    async getById(id) {
        try {
            const res = await axios.get(API_URL + 'interview/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    // Used in getting Candidate Interview Timeline
    async getCandidateJobEvent(cid, jid, iid) {
        try {
            const res = await axios.get(API_URL + "interview/" + jid + "/" + cid + "/" + iid , 
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    // Used in updating Candidate Interview
    async update(cid,jid,data) {
        try {
            const res = await axios.put(API_URL + 'interview?candidate_id='+ cid +'&job_id='+ jid, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    //Not in Use
    async delete(cid,jid) {
        try {
            const res = await axios.delete(API_URL + 'interview?candidate_id='+ cid +'&job_id='+ jid,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new InterviewService();